<mat-toolbar color="secondary">
    <mat-toolbar-row>
      <img class="logo" src="assets\images\logo.png">
      <div class="pl-30">
        <a mat-button routerLink="/items">Noble Items </a>
        <a mat-button routerLink="/vendors">Noble Vendors </a>
        <a mat-button routerLink="/sourcing">DLA Sourcing </a>
        <a mat-button routerLink="/items-ad">Items AD </a>
        <a mat-button (click)="logOut()"> Logout </a>
      </div>
    </mat-toolbar-row>
</mat-toolbar>
<router-outlet></router-outlet>