import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FirebaseService } from 'src/app/services/firebase.service';

@Component({
  selector: 'app-vendor-details',
  templateUrl: './vendor-details.component.html',
  styleUrls: ['./vendor-details.component.scss']
})
export class VendorDetailsComponent implements OnInit {

  public vendor: any = null;

  constructor(public firebaseService: FirebaseService, public dialogRef: MatDialogRef<VendorDetailsComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  async ngOnInit() {
    if(this.data && this.data.vendor_internalid) this.vendor = await this.firebaseService.getVendor(this.data.vendor_internalid).toPromise();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
