<div mat-dialog-content >
  <mat-card class="example-card" *ngIf="vendor">
    <mat-card-header>
      <mat-card-title>{{vendor[0].entityid}} <a href="https://319008.app.netsuite.com/app/common/entity/vendor.nl?e=T&id={{vendor[0].internalid}}" target="_blank"><mat-icon class="nIcon">launch</mat-icon></a></mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <table class="contact">
        <caption class="cap cap-top">Contact Information</caption>
        <tr>
          <td>Email</td>
          <td>{{vendor[0].email}}</td>
        </tr>
        <tr>
          <td>Phone</td>
          <td>{{vendor[0].phone}}</td>
        </tr>
        <tr>
          <td>Website</td>
          <td><a href="{{vendor[0].url}}">{{vendor[0].url}}</a></td>
        </tr>
        <tr>
          <td>Username</td>
          <td>{{vendor[0].custentity_user_name}}</td>
        </tr>
        <tr>
          <td>Password</td>
          <td>{{vendor[0].custentity_password}}</td>
        </tr>
      </table>
      <table class="vendor">
        <caption class="cap cap-bottom">Vendor Information</caption>
        <tr>
          <td>Affliation</td>
          <td>{{vendor[0].custentity_affiliation}}</td>
        </tr>
        <tr>
          <td>Socio Economic</td>
          <td>{{vendor[0].custentityses}}</td>
        </tr>
        <tr>
          <td>Vendor Category</td>
          <td>{{vendor[0].custentity_vendorcat}}</td>
        </tr>
        <tr>
          <td>Vendor Type</td>
          <td>{{vendor[0].custentity3}}</td>
        </tr>
        <tr>
          <td>Inactive</td>
          <td>{{vendor[0].isinactive}}</td>
        </tr>
      </table>
      <mat-divider></mat-divider>
    </mat-card-content>
  </mat-card>
</div>