import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LayoutComponent } from './layout/layout.component';
import { AuthGuard } from './services/auth.guard';

const childrenRoutes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',    
    redirectTo: 'items',
    pathMatch: 'full',
  },  
  { path: 'items', canActivate: [AuthGuard], runGuardsAndResolvers: 'always', loadChildren: () => import('./pages/items/items.module').then(m => m.ItemsModule) },
  { path: 'items-ad', canActivate: [AuthGuard], runGuardsAndResolvers: 'always', loadChildren: () => import('./pages/items-ad/items-ad.module').then(m => m.ItemsAdModule) },
  { path: 'vendors', canActivate: [AuthGuard], runGuardsAndResolvers: 'always', loadChildren: () => import('./pages/vendors/vendors.module').then(m => m.VendorsModule) },
  { path: 'sourcing', canActivate: [AuthGuard], runGuardsAndResolvers: 'always', loadChildren: () => import('./pages/sourcing/sourcing.module').then(m => m.SourcingModule) },
  { path: 'dashboard', canActivate: [AuthGuard], runGuardsAndResolvers: 'always', loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule) },
];

const routes: Routes = [
  { path: 'login', canActivate: [], runGuardsAndResolvers: 'always', loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule) },
  {
    path: '',
    component: LayoutComponent,
    children: childrenRoutes
  }  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
