import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { environment } from '../environments/environment';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAnalyticsModule } from '@angular/fire/analytics';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { LayoutComponent } from './layout/layout.component';
import { FirebaseService } from './services/firebase.service';
import { HttpClientModule } from '@angular/common/http';
import { LayoutModule } from '@angular/cdk/layout';
import { SharedModule } from './shared/shared/shared.module';
import { VendorDetailsComponent } from './pages/vendor-details/vendor-details.component';

const firebaseConfig = {
  apiKey: "AIzaSyAZU8be_ESnrY3-W5EmnwmObqeVBkMfxVg",
  authDomain: "noble-engine.firebaseapp.com",
  databaseURL: "https://noble-engine.firebaseio.com",
  projectId: "noble-engine",
  storageBucket: "noble-engine.appspot.com",
  messagingSenderId: "499804685993",
  appId: "1:499804685993:web:4723482aba3fc9de590bb8",
  measurementId: "G-QC1DNNXCLQ"
}

@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    VendorDetailsComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFireAnalyticsModule,
    AngularFirestoreModule,
    AngularFireAuthModule,
    HttpClientModule,
    SharedModule,
    AppRoutingModule,
    LayoutModule
  ],
  providers: [FirebaseService],
  bootstrap: [AppComponent]
})
export class AppModule { }
