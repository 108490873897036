import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { FirebaseService } from './firebase.service';
import { Observable } from 'rxjs';
import { auth } from 'firebase';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  user: any;
  user$: Observable<any>;
  isLoggedIn = false;

  constructor(private firebaseAuth: AngularFireAuth, private firebaseService: FirebaseService, private router: Router) { 
    this.user$ = this.firebaseAuth.authState;
  }

  async loginUser() {

    
    try {
      const provider = new auth.GoogleAuthProvider();
      const credential = await this.firebaseAuth.signInWithPopup(provider);
      this.user = credential.user;
      if (this.user) {
        if (this.user.email.indexOf('@noble.com') !== -1 || this.user.email.indexOf('oranski@gmail.com') !== -1 || this.user.email.indexOf('@federalresources.com') !== -1 || this.user.email.indexOf('@tssi-ops.com') !== -1) {
          this.isLoggedIn = true;
          this.setLocalUserProfile(this.user);
          this.router.navigate(['/']);
        } else {
          console.log('logout');
          localStorage.setItem('userProfile', null);
          await this.firebaseAuth.signOut();
          this.isLoggedIn = false;
          this.router.navigate(['/login']);
        }
      }
    } catch(err) {
      console.log(err);
      alert(JSON.stringify(err));
    }
  }

  async logOut() {

    console.log('logout');
    localStorage.setItem('userProfile', null);
    await this.firebaseAuth.signOut();
    this.isLoggedIn = false;
    this.router.navigate(['/login']);
 }

  getLocalUserProfile() {
    this.user = JSON.parse(localStorage.getItem("userProfile"));
    if(this.user) this.isLoggedIn = true;
  }

  setLocalUserProfile(user) {
    localStorage.setItem("userProfile", JSON.stringify(user));
  }
}