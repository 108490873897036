import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {


  constructor(private http: HttpClient) { }

  getPricing(params) {
    console.log(params);
    return this.http.post('https://us-central1-noble-engine.cloudfunctions.net/processItems', params);
  }

  getItemsAD(params) {
    console.log(params);
    return this.http.post('https://us-central1-noble-engine.cloudfunctions.net/processItemsAD', params);
  }

  getSourcing(params) {
    console.log(params);
    return this.http.post('https://us-central1-noble-engine.cloudfunctions.net/processVendorSourcing', params);
  }

  getVendors(params) {
    console.log(params);
    return this.http.post('https://us-central1-noble-engine.cloudfunctions.net/processVendors', params);
  }
  
  getVendor(id) {
    console.log(id);
    return this.http.get('https://us-central1-noble-engine.cloudfunctions.net/processVendor?internalid='+id);
  }  

}


